import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getReq } from '../utils/apiCalls';

export const Home = () => {
    document.title = "youngStartup Account | Home"
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!location.pathname || location.pathname === '/') {
            navigate('signup')
        }
        if (localStorage.tok) {
            getReq('https://youngstartup.io/api/db/website/current')
                .then(res => res.json())
                .then((res) => {
                    if (res.id) {
                        navigate(res.url_dest || '/soform')
                    }
                })
                .catch((err) => console.log(err))
        }
    }, [])

    return (
        <div className='home-wrapper'>
            <div className='home'>
                <header>
                    <img
                        alt="YSV-Logo"
                        src="/assets/media/ysv-logo-black-new.png"
                    />
                    <a className='btn' target='blank' href="https://youngstartup.com/upcoming">Back To Event</a>
                </header>
                <Outlet />
                <h6>Questions or issues? Please contact <a href="mailto:topinnovators@youngstartup.com">topinnovators@youngstartup.com</a></h6>
            </div>
        </div>
    )
}





