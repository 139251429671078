import { useContext } from 'react'
import { LandingContext } from '../../pages/LandingContext'
import { AssignPass } from './AssignPass.js'

import styles from './Assign.module.css'

export const Assign = () => {
   const { landingData } = useContext(LandingContext)

   return (
      <div className={styles.assign}>
         {
            landingData.passes.map((ticket, ticketIdx) =>
               <div key={ticketIdx} className={styles.ticket}>
                  <h1>Ticket {ticketIdx + 1}</h1>
                  <AssignPass ticket={ticket} ticketIdx={ticketIdx} tickets={landingData.passes}/>
               </div>
            )
         }
      </div>
   )
}
