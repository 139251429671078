import { useEffect, useState } from 'react'
import { postReq } from '../utils/apiCalls'
import { NavLink, useNavigate } from 'react-router-dom'
import { svgs } from '../assets/svgs'

const emailRegEx = new RegExp(/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook|inbox)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,20}|\d{1,3})(\]?)$/)
const passwordRegEx = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$?%*~^_&+\-)(=]).{8,}$/)
const nameRegEx = new RegExp(/^[a-zA-Z\s]{2,}$/)

export const SignUp = () => {
    document.title = "youngStartup Account | SignUp"
    const navigate = useNavigate()
    const [typePassword, setTypePassword] = useState({ pass1: "password", pass2: "password" })
    const [DDOptions, setDDOptions] = useState({})
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState({ message: '', success: false })
    const [credentials, setCredentials] = useState({
        lastname: "",
        firstname: "",
        email: "",
        company_stage: "",
        role: 103,
        ysv_source_multi: "",
        password: "",
        password_confirmation: "",
    })
    const validations = {
        firstname: {
            msg: 'Please enter a valid first name. ',
            validate: (val) => {
                return nameRegEx.test(val)
            },
        },
        lastname: {
            msg: 'Please enter a valid last name',
            validate: (val) => {
                return nameRegEx.test(val)
            },
        },
        email: {
            msg: 'Please enter your valid corporate email (not gmail, yahoo, outlook, me, iCloud, etc)',
            validate: (val) => {
                return emailRegEx.test(val)
            },
        },
        company_stage: {
            msg: 'Company Stage cannot be empty.',
            validate: (val) => {
                return Boolean(val)
            },
        },
        password: {
            msg: 'Your password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
            validate: (val) => {
                return passwordRegEx.test(val)
            }
        },
        password_confirmation: {
            msg: 'Password and Confirm Password must match. Please try again.',
            validate: (val) => {
                return (val === credentials.password)
            }
        },
    }
    const data = {
        body: {
            ...credentials,
        }
    }

    useEffect(() => {
        fetch("https://youngstartup.io/api/info/property-options-dd/accounts")
            .then(res => res.json())
            .then(data => setDDOptions(data))
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validateSubmit()) return
        setLoader(true)
        postReq('https://youngstartup.io/api/website/create-user', data)
            .then((res) => {
                if ([405, 500].includes(res.status)) {
                    throw new Error('An error occurred')
                }
                if (!res.ok) {
                    return res.json().then((data) => {
                        throw new Error(data.message)
                    })
                }
                return res.json()
            })
            .then(() => { localStorage.setItem('email', credentials.email); navigate('/account-created') })
            .catch((err) => { setMsg({ message: err.message, success: false }) })
            .finally(() => { setLoader(false) })
    }

    const handelChange = (e) => {
        setMsg({ message: '', success: false })
        setCredentials((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
    }

    const handelBlur = (e) => {
        validatInput(e.target.name, e.target.value)
    }

    const togglePassword = (e) => {
        e.preventDefault()
        const name = e.target.closest('button').name
        setTypePassword((pre) => ({ ...pre, [name]: typePassword[name] === 'text' ? 'password' : 'text' }))
    }

    const validatInput = (key, val) => {
        if (!validations[key].validate(val)) {
            setMsg({ message: validations[key].msg, success: false })
            return false
        }
        return true
    }

    const validateSubmit = () => {
        return Object.entries(validations).every(([key, val]) => {
            return validatInput(key, credentials[key])
        })
    }

    return (
        <div className='signup'>
            <h3>Create Your Account</h3>
            <h4><span>Create an account</span><span>Fill out Company Profile</span><span>Apply to present</span></h4>
            <nav>
                <NavLink to='/signup'>Signup</NavLink>
                <NavLink to='/login'>Login</NavLink>
            </nav>
            <span></span>
            <form onSubmit={handleSubmit}>
                <section className='input name'>
                    <input placeholder='Firstname*' id='firstname' type="text" name='firstname' value={credentials.firstname} onChange={handelChange} onBlur={handelBlur} />
                    <input placeholder='Lastname*' id='lastname' type="text" name='lastname' value={credentials.lastname} onChange={handelChange} onBlur={handelBlur} />
                </section>
                <div className='input'>
                    <input placeholder='Email*' id='email' type="email" name='email' value={credentials.email} onChange={handelChange} onBlur={handelBlur} />
                </div>
                <div className='input'>
                    <select id='company_stage' name='company_stage' value={credentials.company_stage} onChange={handelChange} onBlur={handelBlur}>
                        <option value="" disabled>Company Stage*</option>
                        {
                            DDOptions.company_stage?.map((option, optionIdx) =>
                                <option
                                    key={option.id}
                                    value={option.option_value}
                                >{option.option_display}</option>
                            )
                        }
                    </select>
                </div>
                <div className='input'>
                    <select id='ysv_source_multi' name='ysv_source_multi' value={credentials.ysv_source_multi} onChange={handelChange}>
                        <option value="" disabled>Referred by? (Sponsor, Industry Partner, etc)</option>
                        {
                            DDOptions.referred_by?.map((option, optionIdx) =>
                                <option
                                    key={option.id}
                                    value={option.option_value}
                                >{option.option_display}</option>
                            )
                        }
                    </select>
                </div>
                <div className='input password'>
                    <input placeholder='Password*' id='password' type={typePassword.pass1} name='password' value={credentials.password} onChange={handelChange} onBlur={handelBlur} />
                    <button type='button' name='pass1' onClick={togglePassword}>{svgs.eye}</button>
                </div>
                <div className='input password'>
                    <input placeholder='Confirm Password*' id='password_confirmation' type={typePassword.pass2} name='password_confirmation' value={credentials.password_confirmation} onChange={handelChange} onBlur={handelBlur} />
                    <button type='button' name='pass2' onClick={togglePassword}>{svgs.eye}</button>
                </div>
                {msg.message && <div className={msg.success ? 'msg green' : 'msg'}>{msg.message}</div>}
                <div className={loader ? 'loader' : 'btn-container'}>
                    {!loader && <button type='submit' className='btn'>Create Account</button>}
                </div>
            </form>
        </div>
    )
}





