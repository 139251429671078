import { useContext, useEffect, useRef, useState } from "react"
import { SOFormContext } from "../../pages/SOForm"
import { getData, getReq, patchData, patchReq } from "../../utils/apiCalls"
import { DynamicInputs } from "./DynamicInputs"
import { numberToEnglish, validateBase64File } from "../../utils/funcs"
import { SubInputs } from "./SubInputs"
import { FileInput } from "./FileInput"
import { useLocation, useNavigate } from "react-router-dom"

export const SOFormDialog = ({ formId }) => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [msg, setMsg] = useState({ header: '', message: [], success: false })
    const [currStage, setCurrStage] = useState(0)
    const [layout, setLayout] = useState([])
    const [fields, setFields] = useState([])
    const [invalidFields, setInvalidFields] = useState([])
    const [validations, setValidations] = useState({})
    const navigate = useNavigate()
    const location = useLocation()
    const scrollRef = useRef(null)
    const formids = {
        soform: '30',
        fullsoform: '31',
    }

    useEffect(() => {
        getData(`https://youngstartup.io/api/cwebsite/get_form_fields?form_type=form&form_id=${formids[formId]}&table=accounts`)
            .then(res => {
                setFields(res.fields)
                setLayout(res.layout)
            })
        if (!SOFormUser.eventData) {
            getReq('https://youngstartup.io/api/cwebsite/get_current_event')
                .then(res => res.json())
                .then(res => setSOFormUser({ ...SOFormUser, eventData: res }))
        }
    }, []);

    useEffect(() => {
        if (!fields || !fields.length) return
        setValidations(initValidations())
    }, [fields])

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, [currStage])

    useEffect(() => {
        setMsg({ header: '', message: [], success: false })
    }, [SOFormUser.formData])

    const handelSubmit = (e) => {
        e.preventDefault()
        setMsg({ header: '', message: [], success: false })
        if (!validateSubmit()) return
        setMsg({ header: 'Sending Info...', message: [], success: true })
        const payload = { ...SOFormUser.formData, submitted: true }
        patchReq(`https://youngstartup.io/api/db/website/so_forms/update`, { body: payload })
            .then((res) => {
                if ([405, 500].includes(res.status)) {
                    throw new Error('An error occurred')
                }
                if ((res.status === 401)) {
                    localStorage.removeItem('tok')
                    navigate("/login?red_to=" + location.pathname)
                }
                if (!res.ok) {
                    return res.json().then((data) => {
                        throw new Error(data.message)
                    })
                }
                return res.json()
            })
            .then(res => {
                setMsg({ header: '', message: [res.message], success: res.success })
                setSOFormUser({ ...SOFormUser, currStage: 'SubmittedDialog' })
                return res
            })
            .catch(err => {
                setMsg({ header: err.message, message: ['Please contact topinnovators@youngstartup.com'], success: false })
            })
    }

    const handelSave = () => {
        patchData(`https://youngstartup.io/api/db/website/so_forms/update`, { body: SOFormUser.formData })
            .then(res => {
                setMsg({ header: 'Saved Successfully', message: [], success: res.success })
            })
    }

    const handelClick = (val) => {
        setCurrStage(val)
    }

    const handelChange = (e) => {
        e.preventDefault()
        setInvalidFields(invalidFields.filter(i => i !== e.target.name))
        setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, [e.target.name]: e.target.value } })
    }

    const validatInput = (key, val) => {
        const { valid, msg } = validations[key].validate(val)
        if (!valid) {
            setInvalidFields(prev => ([...prev, key]))
            setMsg(prev => ({ header: 'These fields have an error:', message: [...prev.message, msg], success: false }))
            return false
        }
        return true
    }

    const validateSubmit = () => {
        return Object.entries(validations).map(([key, val]) => {
            return validatInput(key, SOFormUser.formData[key])
        }).every(i => i)
    }

    const initValidations = () => {
        const tempValidations = {}
        fields.map(field => {
            tempValidations[field.internal_name] = {
                msg: field.message,
                validate: (val) => {
                    if (field.validations?.accept && (!val.includes('https://youngstartup.io'))) {
                        if (!validateBase64File(val, field.validations.accept)) {
                            return { valid: false, msg: `${field.label} file not supported` }
                        }
                    }
                    if (!field.validations?.required) return { valid: true, msg: '' }
                    if (!val) return { valid: false, msg: `${field.label} is empty!` }
                    if (field.validations?.min) {
                        if (val.length < field.validations.min) {
                            return { valid: false, msg: `${field.label} input is too short. (min of ${field.validations.min} chars)` }
                        }
                    }
                    if (field.validations?.max) {
                        if (val.length > field.validations.max) {
                            return { valid: false, msg: `${field.label} input is too long. (max of ${field.validations.max} chars)` }
                        }
                    }
                    if (field.validations?.pattern) {
                        const pattern = new RegExp(field.validations.pattern)
                        if (!pattern.test(val)) {
                            return { valid: false, msg: `${field.label} is not valid` }
                        }
                    }
                    if (field.validations?.limit) {
                        // JSON array field
                        const tepmVal = JSON.parse(val)
                        if (tepmVal.length > field.validations.limit) return { valid: false, msg: `${field.label} exceeded the limit` }
                        if (!tepmVal.map(i => i[field.validations.sub_property]).join('').trim()) {
                            return { valid: false, msg: `${field.label} has no value` }
                        }
                    }
                    return { valid: true, msg: '' }
                },
            }
        })
        return tempValidations
    }

    return (
        <div className='soform-dialog dialog'>
            {formId === 'soform' && <div>
                <h1>Top Innovator Application</h1>
                <p>Fill out all the required fields to be considered for a presenting slot. The following application should take 3-5 minutes.</p>
                <p>Use the Save button to save your progress to continue at a later date. Please note that your application will only be taken into consideration once the application is complete and you’ve pressed the “Submit” button at the end.</p>
            </div>}
            {formId === 'fullsoform' && <div>
                <h1>Summary Outline Inclusion</h1>
                <p>The below information will be printed in the event guide and distributed to investors in attendance.</p>
                {/* <p>Please ensure that your information is submitted by the conclusion of <span>August 6th</span>. Submissions received after this deadline may <span> not be included </span> in the event guide. </p> */}
                <p>Please ensure that your information is submitted by the deadline noted in the email sent to you from preston@youngstartup.com. Submissions received after that deadline may <span> not be included </span> in the event guide. </p>
            </div>}
            {!fields.length && <div className="loader-container"><div id="loader" /></div>}
            {fields.length !== 0 &&
                <form onSubmit={handelSubmit}>
                    {layout.groups.map((group, groupIdx) =>
                        <div key={groupIdx}>
                            <div className='stage-name' onClick={() => handelClick(groupIdx)}><span>
                                {currStage > groupIdx && groupIdx < layout.groups.length - 1 ? '✓' : groupIdx + 1}</span> {group.name}</div>
                            {currStage !== groupIdx && groupIdx < layout.groups.length - 1 && <span className="line"></span>}
                            {currStage === groupIdx && <div className='inputs-container'>
                                <div className="scroll-ref" ref={scrollRef}></div>
                                {fields.map((input, inputIdx) => (
                                    group.fields.includes(input.id) &&
                                    <div key={input.id} className={`input ${input.element_type} ${invalidFields.includes(input.internal_name) ? 'invalid' : ''}`}>
                                        {
                                            input.element_type === 'input' &&
                                            <input
                                                placeholder={`${input.label}`}
                                                id={input.label}
                                                type={input.type}
                                                name={input.internal_name}
                                                value={SOFormUser.formData[input.internal_name] || ''}
                                                onChange={handelChange}
                                            />
                                        }
                                        {
                                            input.element_type === 'spelled_numbers' &&
                                            <div>
                                                <input
                                                    placeholder={`${input.label}`}
                                                    id={input.label}
                                                    type={input.type}
                                                    name={input.internal_name}
                                                    value={SOFormUser?.formData[input.internal_name]?.toLocaleString('en-US', { style: 'decimal' }) || ''}
                                                    onChange={handelChange}
                                                />
                                                <label htmlFor={input.id}>{input.label} {input.additional_text}</label>
                                                <span>{numberToEnglish(SOFormUser.formData[input.internal_name])}</span>
                                            </div>
                                        }
                                        {
                                            input.element_type === 'select' &&
                                            <select
                                                name={input.internal_name}
                                                id={input.label}
                                                onChange={handelChange}
                                                value={SOFormUser.formData[input.internal_name] || ''}
                                            >
                                                <option value="" disabled>Select an option</option>
                                                {
                                                    input.options?.map((option, optionIdx) =>
                                                        <option
                                                            key={option.id}
                                                            value={option.option_value}
                                                        >{option.option_display}</option>
                                                    )
                                                }
                                            </select>
                                        }
                                        {
                                            input.element_type === 'textarea' &&
                                            <textarea
                                                placeholder={`enter your ${input.label}`}
                                                rows={5}
                                                id={input.label}
                                                type={input.type}
                                                name={input.internal_name}
                                                value={SOFormUser.formData[input.internal_name] || ''}
                                                onChange={handelChange}
                                            />
                                        }
                                        {
                                            input.element_type === 'dynamic' &&
                                            <DynamicInputs input={input} setInvalidFields={setInvalidFields} />
                                        }
                                        {
                                            input.element_type === 'destruction' &&
                                            <SubInputs input={input} />
                                        }
                                        {
                                            input.element_type === 'file' &&
                                            <FileInput input={input} />
                                        }
                                        {input.element_type !== 'spelled_numbers' && <label htmlFor={input.id}>{input.label} {input.additional_text}</label>}
                                    </div>
                                ))}
                            </div>}
                            {groupIdx === layout.groups.length - 1 && (msg.message.length !== 0 || msg.header) && <div className={msg.success ? 'msg green' : 'msg'}><span>{msg.header}</span>{msg.message.map((i, idx) => <span key={idx}>• {i}</span>)}</div>}
                            {currStage === groupIdx && groupIdx === layout.groups.length - 1 && <button type='submit' className='btn'>Submit</button>}
                            {currStage === groupIdx && groupIdx < layout.groups.length - 1 && <button onClick={() => { handelClick(groupIdx + 1); handelSave() }} type='button' className='btn'>Save</button>}
                        </div>
                    )}
                </form>}
        </div>
    )
}










