import { useEffect, useState } from 'react'
import { postReq } from '../utils/apiCalls'
import { Link, useNavigate } from 'react-router-dom';
import { getParameterByName } from '../utils/funcs';
import { svgs } from '../assets/svgs';

const passwordRegEx = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$?%*~^_&+\-)(=]).{8,}$/)

export const ResetPassword = () => {
    document.title = "youngStartup Account | ResetPassword";
    const [typePassword, setTypePassword] = useState({ pass1: "password", pass2: "password" })
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState({ message: '', success: false })
    const [credentials, setCredentials] = useState({
        password: "",
        password_confirmation: "",
    })
    const validations = {
        password: {
            msg: 'Your password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
            validate: (val) => {
                return passwordRegEx.test(val)
            }
        },
        password_confirmation: {
            msg: 'Password and Confirm Password must match. Please try again.',
            validate: (val) => {
                return (val === credentials.password)
            }
        },
    }
    useEffect(() => {
        localStorage.setItem('tok', getParameterByName("tok"))
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validateSubmit()) return
        if (!localStorage.tok || localStorage.tok === "null") return setMsg({ message: 'Link not valid, Please resend the reset password email', success: false })
        setLoader(true)
        const payload = {
            body: {
                ...credentials,
            }
        }
        postReq('https://youngstartup.io/api/website/resetpass/stage2', payload)
            .then((res) => {
                if ([405, 500].includes(res.status)) {
                    throw new Error('An error occurred')
                }
                if (!res.ok) {
                    return res.json().then((data) => {
                        throw new Error(data.message)
                    })
                }
                return res.json()
            })
            .then((res) => {
                localStorage.setItem('tok', res.tok)
                setMsg({ message: res.message, success: res.success })
                navigate(res.url_dest || '/soform')
            })
            .catch((err) => { setMsg({ message: err.message, success: false }) })
            .finally(() => { setLoader(false) })
    }

    const handelChange = (e) => {
        setMsg({ message: '', success: false })
        setCredentials((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
    }

    const handelBlur = (e) => {
        validatInput(e.target.name, e.target.value)
    }

    const togglePassword = (e) => {
        e.preventDefault()
        const name = e.target.closest('button').name
        setTypePassword((pre) => ({ ...pre, [name]: typePassword[name] === 'text' ? 'password' : 'text' }))
    }

    const validatInput = (key, val) => {
        if (!validations[key].validate(val)) {
            setMsg({ message: validations[key].msg, success: false })
            return false
        }
        return true
    }

    const validateSubmit = () => {
        return Object.entries(validations).every(([key, val]) => {
            return validatInput(key, credentials[key])
        })
    }

    return (
        <div className='reset-password'>
            <h3>RESET PASSWORD</h3>
            <h4>Choose a new password for your account. it must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.</h4>
            <span></span>
            <form onSubmit={handleSubmit}>
                <div className='input password'>
                    <input placeholder='Password*' id='password' type={typePassword.pass1} name='password' value={credentials.password} onChange={handelChange} onBlur={handelBlur} />
                    <button type='button' name='pass1' onClick={togglePassword}>{svgs.eye}</button>
                </div>
                <div className='input password'>
                    <input placeholder='Confirm Password*' id='password_confirmation' type={typePassword.pass2} name='password_confirmation' value={credentials.password_confirmation} onChange={handelChange} onBlur={handelBlur} />
                    <button type='button' name='pass2' onClick={togglePassword}>{svgs.eye}</button>
                </div>
                {msg.message && <div className={msg.success ? 'msg green' : 'msg'}>{msg.message}</div>}
                <div className={loader ? 'loader' : 'btn-container'}>
                    {!loader && <button type='submit' className='btn'>Submit</button>}
                </div>
            </form>
            <h5>Back to <Link to='/login'>Login</Link></h5>
        </div>
    )
}







