import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import ChunkedUploady from "@rpldy/chunked-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import { useItemProgressListener, useItemErrorListener, useItemFinishListener } from "@rpldy/uploady";
import { LandingContext } from '../../pages/LandingContext';

import { svgs } from '../../assets/svgs';
import { getMonthAndDayWithSuffix } from '../../utils/funcs';
import { SubmitButton } from '../SubmitButton';
import styles from './PresentationUpload.module.css';

const msgs = {
   current: 'Current file',
   success: 'Valid file',
   type: 'Invalid file (pptx, ppsx, pdf only!)',
   empty: 'No file selected',
   uploading: 'Uploading file...',
   submit: 'Upload successful',
   error: 'Upload failed',
}


export const PresentationUpload = () => {
   const { landingData, updateLandingData } = useContext(LandingContext)
   const [msg, setMsg] = useState(landingData.user.so_application.presentation_link ? msgs.current : msgs.empty);
   const [link, setLink] = useState(() => landingData.user.so_application.presentation_link || '')
   const [openPopup, setOpenPopup] = useState(false)

   useEffect(() => {
      if (msg === msgs.uploading) {
         setOpenPopup(true)
      }
   }, [msg])

   const handelSubmit = () => {
      if (msg !== msgs.success) return
      updateLandingData()
      setTimeout(() => {
         setMsg(msgs.submit)
      }, 2000)
   }

   const filterByType = useCallback((file) => {
      const fileIsValid = file.name.includes(".pptx") || file.name.includes(".ppsx") || file.name.includes(".pdf");
      if (!fileIsValid) return setMsg(msgs.type);
      setMsg(msgs.uploading)
      return fileIsValid;
   }, []);

   const DivUploadButton = asUploadButton(forwardRef(
      (props, ref) => {
         return (
            <label {...props}>
               {svgs.upload}
               <p>{landingData.user.so_application.presentation_link ? 'Change file' : 'Upload file'}</p>
            </label>
         )
      }
   ));
   const FinishListener = () => {
      window.onbeforeunload = null
      useItemFinishListener((item) => {
         setMsg(msgs.success);
         setLink(item.uploadResponse.results.find(r => r.data?.path).data.path);
      });
   }
   const ProgressListener = () => {
      if (msg === msgs.uploading) {
         if (!window.onbeforeunload) {
            window.onbeforeunload = (e) => {
               e.preventDefault();
               return 'Are you sure you want to leave this page?';
            }
         }
      }
      const item = useItemProgressListener((item) => {
      });
      return <div style={{ visibility: msg !== msgs.uploading && 'hidden' }} className={styles.progressBar}>
         <label htmlFor="file">File progress: {(item?.completed) && `${(item.completed.toFixed(0))}%`}</label>
         <progress id="file" max="100" value={item?.completed || 0} > </progress>
      </div>
   };

   const ErrorListener = () => {
      useItemErrorListener((item) => {
         setMsg(msgs.error);
      });
   };

   return (
      <div className={styles.presentation}>
         <h1>PowerPoint file upload</h1>
         <ol>
            <li>Only the most recent upload will be saved</li>
            <li>Your presentation will be {landingData?.user?.so_application?.so_marking?.final_presenting_track?.toLowerCase() !== 'seed' ? 6 : 3} minutes long.</li>
            <li>Only .pptx or .pdf files are accepted, .pptx is highly recommended.</li>
            <li>Files must be submitted by {getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_3)}</li>
            <li>Aspect ratio must be 16:9. This is the default aspect ratio of Powerpoint and Google Slides</li>
            <li>Embedded videos must be in mp4 or mpeg format. If embedding a video in your presentation, please do not use a pdf presentation. To embed a video from your pc <a href='https://support.microsoft.com/en-au/office/insert-and-play-a-video-file-from-your-computer-f3fcbd3e-5f86-4320-8aea-31bff480ed02' target='blank'>click here</a>. To embed a youtube video <a href='https://support.microsoft.com/en-au/office/insert-a-video-from-youtube-or-another-site-8340ec69-4cee-4fe1-ab96-4849154bc6db#:~:text=In%20PowerPoint%2C%20click%20the%20slide,and%20resize%20as%20you%20like' target='blank'>click here</a>.</li>
            <li><span>Do not</span> include ANY external links to be used during your presentation</li>
            <li>Name your file: COMPANY (If your company name has more than one word, replace all spaces with underscores. For example: a company called "Mike's Awesome Startup" would name the file: MIKES_AWESOME_STARTUP)</li>
         </ol>
         <section className={styles.file}>
            <p
               className={styles.statusMsg}
               style={{ color: msg === msgs.type || msg === msgs.empty || msg === msgs.error ? 'red' : 'green' }}>
               {msg}
            </p>
            {link && <div className={styles.link}>
               Download the file currently uploaded {svgs.download}
               <a href={link} target="_blank" rel="noreferrer">click here</a>
            </div>}

            <ChunkedUploady
               method="POST"
               destination={{
                  url: "https://youngstartup.io/api/db/website/presentation/upload_large",
                  headers: {
                     tok: localStorage.tok,
                  }
               }}
               chunkSize={20 * 1000 * 1024} //20mb
               inputFieldName={'file'}
               accept=".pptx, .ppsx, .pdf"
               fileFilter={filterByType}
            >
               <DivUploadButton />
               <FinishListener />
               {openPopup &&
                  <div className={styles.filePopup}>
                     {msg === msgs.uploading &&
                        <div>
                           <h1>Your file is uploading...</h1>
                           <ProgressListener />
                        </div>}
                     {msg !== msgs.uploading &&
                        <div>
                           <h2>Your file uploaded successfully!</h2>
                           <button onClick={() => { setOpenPopup(false) }} className={styles.exe}>close</button>
                        </div>}
                  </div>
               }
               <ErrorListener />
            </ChunkedUploady>
         </section>
         <button className={styles.submitBtn} onClick={handelSubmit}>
            <SubmitButton
               color='#0095C3'
               bgColor='#0F1A28'
               borderColor='white'
               clickable={msg === msgs.success}
            />
         </button>
      </div >
   );
}
