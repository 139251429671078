import { useContext } from "react"
import { SOFormContext } from "../../pages/SOForm"


export const SubmittedDialog = () => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)


    return (
        <div className='submitted-dialog dialog'>
            <h1>Application Submitted!</h1>
            <p>Thank you for taking the time to fill out the application. It has been forwarded to the review committee.</p>
            <p>Any changes you make to your application will update your previous application, so feel free to add or update anything as more information becomes available.</p>
            <p>Please whitelist @youngstartup.com so you don't miss any updates!</p>
            <div className='btn-container'>
                <button className='btn' onClick={() => setSOFormUser({ ...SOFormUser, currStage: 'SOFormDialog' })}>Back To Application</button>
            </div>
        </div>
    )
}





