import { useContext, useEffect, useState } from 'react'
import { SOFormContext } from '../../pages/SOForm'

export const SubInputs = ({ input }) => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [fields, setFields] = useState()
    const [values, setValues] = useState()

    useEffect(() => {
        setFields(input.sub_form_fields)
        setValues(JSON.parse(SOFormUser.formData.annual_revenue || '{}') || {})
    }, []);

    useEffect(() => {
        setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, [input.internal_name]: JSON.stringify(values) } })
    }, [values])


    const subInputs = (data) => {
        const sorted = data.sort((a, b) => {
            const aNumber = Number(a.internal_name.slice(-2));
            const bNumber = Number(b.internal_name.slice(-2));
            if (aNumber !== bNumber) {
                return aNumber - bNumber;
            }
            const order = { revenue: 1, ebidta: 2, pre_tax: 3, netIncome: 4 };
            const aPrefix = a.internal_name.slice(0, a.internal_name.length - 2);
            const bPrefix = b.internal_name.slice(0, b.internal_name.length - 2);
            if (order[aPrefix] < order[bPrefix]) {
                return -1;
            } else if (order[aPrefix] > order[bPrefix]) {
                return 1;
            } else {
                return 0;
            }
        });

        const groupedData = []
        for (let i = 0; i < sorted.length; i += 4) {
            const group = [sorted[i], sorted[i + 1], sorted[i + 2], sorted[i + 3]].filter(Boolean);
            groupedData.push(group);
        }
        return groupedData
    }


    const handelSubChange = (e) => {
        setValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
    }

    if (!fields || !values) return <></>

    return (
        <table>
            <thead>
                <tr>
                    <td></td>
                    <th>Revenue</th>
                    <th>EBITDA</th>
                    <th>Pre-tax</th>
                    <th>Net Income</th>
                </tr>
            </thead>
            <tbody>
                {
                    subInputs(fields).map((year, index) => (
                        <tr key={index}>
                            <th >{(new Date().getFullYear()) + index}</th>
                            {
                                year.map((subInput, index) =>
                                    <td key={index} style={{ padding: '0.5em', position: 'relative' }}>
                                        <input
                                            placeholder={`${subInput.label}`}
                                            minLength={subInput?.validations?.min}
                                            maxLength={subInput?.validations?.max}
                                            id={subInput.label}
                                            type={subInput.type}
                                            required={subInput?.validations?.required}
                                            name={subInput.internal_name}
                                            value={values[subInput.internal_name] || ''}
                                            onChange={handelSubChange}
                                            pattern={subInput?.validations?.pattern}
                                        />
                                        <label htmlFor={subInput.label}>$</label>
                                    </td>
                                )
                            }
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
