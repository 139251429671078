import React, { useContext, useEffect, useState } from 'react'
import { LandingContext } from '../../pages/LandingContext';
import { getData, patchData } from '../../utils/apiCalls';
import { SubmitButton } from '../SubmitButton';
import { DynamicInputs } from './DynamicInputs';
import { SubInputs } from './SubInputs';
import styles from './Summary.module.css'

export const Summary = () => {
   const { landingData, updateLandingData } = useContext(LandingContext)
   const [fields, setFields] = useState([])
   const [values, setValues] = useState({})
   const [subValues, setSubValues] = useState({})
   const [dynamicValues, setDynamicValues] = useState({})
   
   useEffect(() => {
      getData(`https://youngstartup.io/api/cwebsite/get_form_fields?form_type=form&form_id=27&table=accounts`)
         .then(res => {
            setFields(res.fields)
            setValues(landingData.user.so_application)
            setSubValues(JSON.parse(landingData.user.so_application.annual_revenue))
            setDynamicValues({
               current_investors: JSON.parse(landingData.user.so_application.current_investors),
               management_team: JSON.parse(landingData.user.so_application.management_team)
            })
         })
   }, []);

   const handelSubmit = (e) => {
      e.preventDefault()
      const payload = values
      // payload.management_team = JSON.stringify({ id: 1, name: 'chaim' })
      // payload.current_investors = JSON.stringify({ id: 1, name: 'joe' })
      payload.management_team = JSON.stringify(dynamicValues.management_team)
      payload.current_investors = JSON.stringify(dynamicValues.current_investors)
      payload.annual_revenue = JSON.stringify(subValues)
      patchData(`https://youngstartup.io/api/db/website/so_forms/update`, {
         body: payload
      }).then(res => {
         updateLandingData()
         // setValues(landingData.user.so_application)
         return res
      }
      )
   }

   const handelChange = (e) => {
      setValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
   }


   return (
      <div className={styles.summary}>
         {!fields.length > 0 && <div id="loader" style={{ top: '4em' }} />}
         {fields.length > 0 && <form onSubmit={handelSubmit}>
            {fields?.map((input, inputIdx) =>
               <div key={input.id} className={`${styles.input} ${input.element_type === 'textarea' && styles.textarea} ${input.element_type === 'select' && styles.select} ${input.element_type === 'dynamic' && styles.dynamic} ${input.element_type === 'destruction' && styles.sub}`}>
                  {
                     input.element_type === 'input' &&
                     <input
                        placeholder={`${input.label}`}
                        minLength={input?.validations?.min}
                        maxLength={input?.validations?.max}
                        id={input.label}
                        type={input.type}
                        required={input?.validations?.required}
                        name={input.internal_name}
                        value={values[input.internal_name] || ''}
                        onChange={handelChange}
                        pattern={input?.validations?.pattern}
                     />
                  }
                  {
                     input.element_type === 'select' &&
                     <select
                        name={input.internal_name}
                        id={input.label}
                        onChange={handelChange}
                        defaultValue={values[input.internal_name] || ''}
                        required={input?.validations?.required}
                     >
                        <option value="" disabled>Select an option</option>
                        {
                           input.options.map((option, optionIdx) =>
                              <option
                                 key={option.id}
                                 value={option.option_value}
                              >{option.option_display}</option>
                           )
                        }
                     </select>
                  }
                  {
                     input.element_type === 'textarea' &&
                     <textarea
                        placeholder={`enter your ${input.label}`}
                        minLength={input?.validations?.min}
                        maxLength={input?.validations?.max}
                        id={input.label}
                        type={input.type}
                        required={input?.validations?.required}
                        name={input.internal_name}
                        value={values[input.internal_name] || ''}
                        onChange={handelChange}
                        pattern={input.validations.pattern}
                     />
                  }
                  {
                     input.element_type === 'dynamic' &&
                     <DynamicInputs input={input} setDynamicValues={setDynamicValues} />
                  }
                  {
                     input.element_type === 'destruction' &&
                     <SubInputs input={input} setSubValues={setSubValues} />
                  }
                  <label htmlFor={input.id}>{input.label}</label>
               </div>
            )
            }
            {
               <button>
                  <SubmitButton bgColor='#0095C3' clickable={true} />
               </button>
            }
         </form>}
      </div>
   )
}
