
export const convertBase64 = (file) => {
   return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
         resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
         reject(error);
      };
   });
};

export const getMonthAndDay = (date) => {
   return new Date(date).toLocaleDateString("en-US", { month: 'long', day: 'numeric' })
}

export const getMonthAndDayWithSuffix = (_date) => {
   const date = new Date(_date);
   let day = date.getDate();
   if (day >= 11 && day <= 13) {
      day = day + "th";
   } else {
      switch (day % 10) {
         case 1: day = day + "st";
            break
         case 2: day = day + "nd";
            break
         case 3: day = day + "rd"
            break;
         default: day = day + "th";
            break;
      }
   }
   const monthName = date.toLocaleString('en-US', { month: 'long' });
   if (monthName === 'Invalid Date') return null
   return `${monthName} ${day}`;
}

export const getTimeOf = (date) => {
   const res = new Date(date).toLocaleTimeString("en-US", { hour12: true, hour: "numeric", minute: "2-digit" })
   return res === 'Invalid Date' ? null : res
}

export const getNextDay = date => {
   var ms = new Date(date).getTime() + (1000 * 60 * 60 * 24);
   let newDate = new Date(ms);
   return getMonthAndDay(newDate)
}

export const unsetLocalTok = (tok) => {
   localStorage.removeItem("tok");
};

export const debounce = (func, wait) => {
   let timeout;

   return function executedFunction(...args) {
      const later = () => {
         clearTimeout(timeout);
         func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
   };
};


export const updateObjectById = (array, id, newData) => {
   const index = array.findIndex(obj => obj.id === id);
   if (index === -1) {
      // ID not found in the array
      return array;
   } else {
      const updatedObject = { ...array[index], ...newData };
      const updatedArray = [...array];
      updatedArray[index] = updatedObject;
      return updatedArray;
   }
}

export function getParameterByName(name, url = window.location.href) {
   name = name.replace(/[\[\]]/g, "\\$&");
   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return "";
   return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function numberToEnglish(n) {
   var string = n?.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words,
      and = "and";
   /* Remove spaces and commas */
   string = string?.replace(/[, ]/g, "");
   /* Is number zero? */
   if (parseInt(string) === 0) {
      return "zero";
   }
   /* Array of units as words */
   units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
   ];
   /* Array of tens as words */
   tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
   ];
   /* Array of scales as words */
   scales = [
      "",
      "thousand",
      "million",
      "billion",
      "trillion",
      "quadrillion",
      "quintillion",
      "sextillion",
      "septillion",
      "octillion",
      "nonillion",
      "decillion",
      "undecillion",
      "duodecillion",
      "tredecillion",
      "quatttuor-decillion",
      "quindecillion",
      "sexdecillion",
      "septen-decillion",
      "octodecillion",
      "novemdecillion",
      "vigintillion",
      "centillion",
   ];
   /* Split user argument into 3 digit chunks from right to left */
   start = string?.length;
   chunks = [];
   while (start > 0) {
      end = start;
      chunks.push(string?.slice((start = Math.max(0, start - 3)), end));
   }
   /* Check if function has enough scale words to be able to stringify the user argument */
   chunksLen = chunks?.length;
   if (chunksLen > scales?.length) {
      return "";
   }
   /* Stringify each integer in each chunk */
   words = [];
   for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);
      if (chunk) {
         /* Split chunk into array of individual integers */
         ints = chunks[i]
            .split("")
            .reverse()
            .map(parseFloat);
         /* If tens integer is 1, i.e. 10, then add 10 to units integer */
         if (
            ints[1] === 1
         ) {
            ints[0] += 10;
         }
         /* Add scale word if chunk is not zero and array item exists */
         if (
            (word = scales[i])
         ) {
            words?.push(word);
         }
         /* Add unit word if array item exists */
         if ((word = units[ints[0]])) {
            words.push(word);
         }
         /* Add tens word if array item exists */
         if ((word = tens[ints[1]])) {
            words.push(word);
         }
         /* Add 'and' string after units or tens integer if: */
         if (
            ints[0] ||
            ints[1]
         ) {
            /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
            if (ints[2] || (!i && chunksLen)) {
               words.push(and);
            }
         }
         /* Add hundreds word if array item exists */
         if (word = units[ints[2]]) {
            words.push(word + " hundred");
         }
      }
   }
   return words.reverse().join(" ").replace(/^and/, '');
}


export function validateBase64File(base64Data, allowedMimeTypes) {
   // Convert the list of mime types to an array, if it's not already one.
   if (!Array.isArray(allowedMimeTypes)) {
     allowedMimeTypes = allowedMimeTypes.split(",");
   }
 
   // Extract the mime type from the base64 data
   const extractedMimeType = base64Data.match(/^data:(.*?);/)?.[1];
 
   // Check if the extracted mime type is present in the allowedMimeTypes array
   if (extractedMimeType && allowedMimeTypes.includes(extractedMimeType)) {
     return true; // The base64 data is valid
   } else {
     return false; // The base64 data is invalid
   }
 }
 