import { useState } from 'react';
import { svgs } from '../assets/svgs';
import styles from './SubmitButton.module.css'
export const SubmitButton = ({ color, bgColor, borderColor, clickable }) => {
   const [isClicked, setIsClicked] = useState(false);
   const [isSaved, setIsSaved] = useState(false);

   const handleClick = () => {
      if (!clickable || isSaved) return
      setIsClicked(true)
      setIsSaved(true)
      setTimeout(() => {
         setIsClicked(false)
      }, 3500)
   }

   return (
      <div onClick={handleClick} className={`${styles.submitButton} ${isClicked && styles.clicked} `} style={{ borderColor: borderColor, background: bgColor, color: color }}>
         <span>{isSaved ? 'SAVED!' : 'SAVE'}</span>
         {svgs.save}
         {svgs.success}
      </div>
   )
}
