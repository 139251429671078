import { useEffect, useRef, useState } from "react"
import { convertBase64 } from "../utils/funcs"
import { svgs } from "../assets/svgs"
import styles from '../components/Landing/Info.module.css'

const msgs = {
    current: 'Current image',
    success: 'Valid image',
    select: 'No image selected',
    small: 'Invalid image (too small)',
    large: 'Invalid image (too big)',
    type: 'Invalid image (PNG or JPG only)',
    error: 'There was an error uploading the image',
}

export const ImageUploader = ({ source, updateData, propName }) => {
    const [image, setImage] = useState(null);
    const [msg, setMsg] = useState(source ? msgs.current : msgs.select);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!image) return
        updateData({ [propName]: image })
            .catch(() => {
                setMsg(msgs.error);
            });
    }, [image])

    useEffect(() => {
        setMsg(source ? msgs.current : msgs.select)
    }, [source])

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            setMsg(msgs.type);
            event.target.value = "";
            setImage('')
            return;
        }
        if (file.size > 4194304) {
            setMsg(msgs.large);
            event.target.value = "";
            setImage('')
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = async () => {
            if (img.width < 30 || img.height < 10) {
                setMsg(msgs.small);
                event.target.value = "";
                setImage('')
                return;
            }
        };

        const base64 = await convertBase64(file)
        setImage(base64)
        setMsg(msgs.success);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            setMsg(msgs.type);
            e.dataTransfer.files[0] = "";
            setImage('')
            return;
        }
        if (file.size > 4194304) {
            setMsg(msgs.large);
            e.dataTransfer.files[0] = "";
            setImage('')
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = async () => {
            if (img.width < 30 || img.height < 10) {
                setMsg(msgs.small);
                e.dataTransfer.files[0] = "";
                setImage('')
                return;
            }
        };
        const base64 = await convertBase64(e.dataTransfer.files[0])
        setImage(base64)
    }

    return (
        <section
            className={styles.image}
            onClick={() => fileInputRef.current.click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <img src={image === null ? source : image} alt="" />
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name='image'
                onChange={handleImageUpload}
                hidden
            />
            {(msg === msgs.error || msg === msgs.type || msg === msgs.large || msg === msgs.small || msg === msgs.select)
                && <div className={styles.uploadLabel} >
                    {svgs.upload}
                    <p>Upload Image</p>
                </div>}
            <div className={styles.statusMsg}>
                <p
                    style={{ color: msg === msgs.success || msg === msgs.current ? 'green' : 'red' }}>
                    {msg}
                </p>
                <p>
                    {(msg === msgs.success || msg === msgs.current) && 'to change image click here'}
                    {(msg === msgs.success || msg === msgs.current) && svgs.upload}
                </p>
            </div>
        </section>
    )
}