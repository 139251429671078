import {
   Home,
   Login,
   Landing,
   SignUp,
   ForgotPassword,
   ResetPassword,
   AccountCreated,
   SOForm,
} from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

function App() {
   return (
      <Router>
         <Routes>
            <Route path="/" element={<Home />} >
               <Route path="forgot-password" element={<ForgotPassword />} />
               <Route path="reset-password" element={<ResetPassword />} />
               <Route path="account-created" element={<AccountCreated />} />
               <Route path="signup" element={<SignUp />} />
               <Route path="login" element={<Login />} />
            </Route>
            <Route path="soform" element={<SOForm formId={'soform'} />} />
            <Route path="fullsoform" element={<SOForm formId={'fullsoform'} />} />
            <Route path="landing/*" element={<Landing />} />
         </Routes>
      </Router>
   );
}

export default App;
