import { useState } from 'react'
import { postReq } from '../utils/apiCalls'
import { Link } from 'react-router-dom';


export const ForgotPassword = () => {
   document.title = "youngStartup Account | ForgotPassword";
   const [msg, setMsg] = useState({ message: '', success: false })
   const [loader, setLoader] = useState(false)
   const [credentials, setCredentials] = useState({
      email: "",
   })

   const handleSubmit = (e) => {
      e.preventDefault()
      if (!credentials.email) return setMsg({ message: 'The email field is required!', success: false })
      setLoader(true)
      const payload = {
         body: {
            ...credentials,
         }
      }
      postReq('https://youngstartup.io/api/website/resetpass/stage1', payload)
         .then((res) => {
            if ([405, 500].includes(res.status)) {
               throw new Error('An error occurred')
            }
            if ((res.status === 404)) {
               throw new Error('That email address is not in our system. Please try again, or create an account.')
            }
            if (!res.ok) {
               return res.json().then((data) => {
                  throw new Error(data.message)
               })
            }
            return res.json()
         })
         .then((res) => { setMsg({ message: res.message, success: res.success }) })
         .catch((err) => { setMsg({ message: err.message, success: false }) })
         .finally(() => { setLoader(false) })
   }

   const handelChange = (e) => {
      setMsg({ message: '', success: false })
      setCredentials((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
   }

   return (
      <div className='forgot-password'>
         <h3>FORGOT PASSWORD</h3>
         <h4>Enter your email to receive a reset password link.</h4>
         <span></span>
         <form onSubmit={handleSubmit}>
            <div className='input'>
               <input placeholder='Email*' id='email' type="email" name='email' value={credentials.email} onChange={handelChange} />
            </div>
            {msg.message && <div className={msg.success ? 'msg green' : 'msg'}>{msg.message}</div>}
            <div className={loader ? 'loader' : 'btn-container'}>
               {!loader && <button type='submit' className='btn'>Submit</button>}
            </div>
         </form>
         <h5>Back to <Link to='/login'>Login</Link></h5>
      </div>
   )
}





