import { useState } from 'react'
import { postReq } from '../utils/apiCalls'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { svgs } from '../assets/svgs';
import { getParameterByName } from '../utils/funcs';

const emailRegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

export const Login = () => {
   document.title = "youngStartup Account | Login";
   const navigate = useNavigate()
   const [passwordType, setPasswordType] = useState("password")
   const [confirmEmail, setConfirmEmail] = useState(false)
   const [loader, setLoader] = useState(false)
   const [msg, setMsg] = useState({ message: '', success: false })
   const [credentials, setCredentials] = useState({
      email: "",
      password: "",
   })
   const validations = {
      email: {
         msg: 'Please enter a vaild email address',
         validate: (val) => {
            return emailRegExp.test(val)
         },
      },
      password: {
         msg: 'Incorrect password!',
         validate: (val) => {
            return Boolean(val)
         }
      },
   }
   const payload = {
      body: {
         ...credentials,
      }
   }
   const handleSubmit = (e) => {
      e.preventDefault()
      if (!validateSubmit()) return
      setLoader(true)
      localStorage.setItem('email', credentials.email)
      postReq('https://youngstartup.io/api/website/login', payload)
         .then((res) => {
            if ([405, 500].includes(res.status)) {
               throw new Error('An error occurred')
            }
            if (!res.ok) {
               return res.json().then((data) => {
                  throw new Error(data.message)
               })
            }
            return res.json()
         })
         .then((data) => {
            localStorage.setItem('tok', data.tok)
            const red_to = getParameterByName('red_to')
            if (red_to) { navigate(red_to) }
            else navigate(data.url_dest || '/soform')
         })
         .catch((err) => {
            if (err.message.includes('Your email has yet to be confirmed.')) setConfirmEmail(true)
            setMsg({ message: err.message, success: false })
         })
         .finally(() => { setLoader(false) })
   }

   const handelChange = (e) => {
      setMsg({ message: '', success: false })
      setCredentials((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
   }

   const togglePassword = (e) => {
      e.preventDefault()
      if (passwordType === "password") {
         setPasswordType("text")
         return
      }
      setPasswordType("password")
   }

   const handelBlur = (e) => {
      validatInput(e.target.name, e.target.value)
   }

   const validatInput = (key, val) => {
      if (!validations[key].validate(val)) {
         setMsg({ message: validations[key].msg, success: false })
         return false
      }
      return true
   }

   const validateSubmit = () => {
      return Object.entries(validations).every(([key, val]) => {
         return validatInput(key, credentials[key])
      })
   }

   const sendConfirmEmail = () => {
      const tempPayload = {
         body: {
            email: credentials.email,
         }
      }
      postReq('https://youngstartup.io/api/website/resendconfirmemail', tempPayload)
   }


   return (
      <div className='login'>
         <h3>Login To Your Account</h3>
         <nav>
            <NavLink to='/signup'>Signup</NavLink>
            <NavLink to='/login'>Login</NavLink>
         </nav>
         <span></span>
         <form onSubmit={handleSubmit}>
            <div className='input'>
               <input required placeholder='Email*' id='email' type="email" name='email' value={credentials.email} onChange={handelChange} onBlur={handelBlur} />
            </div>
            <div className='input password'>
               <input required placeholder='Password*' id='password' type={passwordType} name='password' value={credentials.password} onChange={handelChange} onBlur={handelBlur} />
               <button type='button' onClick={togglePassword}>{svgs.eye}</button>
            </div>
            {msg.message && <div className={msg.success ? 'msg green' : 'msg'}>{msg.message}</div>}
            {confirmEmail && <h5><Link onClick={sendConfirmEmail} to='/account-created'>Resend confirmation email</Link></h5>}
            <div className={loader ? 'loader' : 'btn-container'}>
               {!loader && <button type='submit' className='btn'>Login</button>}
            </div>
         </form>
         <h5><Link to='/forgot-password'>Forgot password?</Link></h5>
         <h5>Don’t have an account? <Link to='/signup'>Sign up</Link></h5>
      </div>
   )
}





