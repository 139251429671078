import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Assign, Guide, Home, Info, Header, PresentationUpload, SideBar, Summary, PresentationSchedule, MarketingTools } from '../components';
import { getData, getReq } from '../utils/apiCalls';
import { LandingContext } from './LandingContext.js';


export const Landing = () => {
   document.title = "youngStartup Account | Landing";
   const [isSideBarOpen, setIsSideBarOpen] = useState(false);
   const [landingData, setLandingData] = useState({});
   const navigate = useNavigate();
   const location = useLocation()

   useEffect(() => {
      updateLandingData();
      getData('https://youngstartup.io/api/cwebsite/get_current_event')
         .then(res => setLandingData(prev => ({ ...prev, eventData: res })))
      location.pathname === '/landing' && navigate('home');
   }, []) // eslint-disable-line 

   const updateLandingData = () => {
      getReq("https://youngstartup.io/api/db/website/dashboard/get")
         .then((res) => {
            if (res.status === 401) {
               navigate('/login?red_to=' + location.pathname)
            }
            if (res.status === 403) {
               navigate('/soform')
            }
            return res.json()
         }).then((res) => setLandingData(prev => ({ ...prev, ...res })))
   }

   const toggleSideBar = () => {
      setIsSideBarOpen(!isSideBarOpen);
   }

   if (!landingData.attendee_data) return <></>;

   return (
      <LandingContext.Provider value={{ landingData, updateLandingData }}>
         <div className='landing'>
            <aside className={isSideBarOpen ? 'open' : 'false'}>
               <SideBar isSideBarOpen={isSideBarOpen} toggleSideBar={toggleSideBar} />
            </aside>

            <header onMouseEnter={() => setIsSideBarOpen(false)}>
               <Header />
            </header>

            <main onMouseEnter={() => setIsSideBarOpen(false)}>
               <Routes>
                  <Route path='/home' element={<Home />} />
                  <Route path='info' element={<Info />} />
                  <Route path='marketing_tools' element={<MarketingTools />} />
                  <Route path='summary' element={<Summary />} />
                  <Route path='presentation_upload' element={<PresentationUpload />} />
                  <Route path='assign' element={<Assign />} />
                  <Route path='presentation_schedule' element={<PresentationSchedule />} />
                  <Route path='guide' element={<Guide />} />
               </Routes>
            </main>
         </div>
      </LandingContext.Provider>
   )
}
