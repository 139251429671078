import { useContext } from 'react'
import { LandingContext } from '../../pages/LandingContext'
import { getMonthAndDayWithSuffix, getTimeOf } from '../../utils/funcs'
import styles from './PresentationSchedule.module.css'

export const PresentationSchedule = () => {
   const { landingData } = useContext(LandingContext)
   const { presenters_in_round, attendee_data } = landingData
   const { short_name, datetime_start, day, round_number } = landingData.presenting_session || {}
   const { final_presenting_track, presenting_order_slot, presenting_round } = attendee_data

   const standBy = 'To be announced'
   const isStandBy = !presenting_order_slot || !presenting_round

   return (
      <div className={styles.presentationSchedule}>
         {
            isStandBy &&
            <section className={styles.scheduleTable}>
               <header>
                  <h6>Track :<p>{standBy}</p></h6>
                  <h6>Round :<p>{standBy}</p></h6>
               </header>
               <ol>
                  {
                     Array(8).fill(1).map((li, liIdx) =>
                        <li key={liIdx}>{standBy}</li>
                     )
                  }
               </ol>
            </section>
         }
         {
            !isStandBy &&
            <section className={styles.scheduleTable}>
               <header>
                  <h6>Track :<p>{short_name || final_presenting_track}</p></h6>
                  <h6>Day {day} :<p>{getMonthAndDayWithSuffix(datetime_start) || standBy}</p></h6>
                  <h6>Round {round_number} :<p>{getTimeOf(datetime_start) || standBy}</p></h6>
               </header>
               <ol>
                  {
                     presenters_in_round.map(presenter =>
                        <li key={presenter.id} style={{ fontWeight: presenter.is_current_presenter ? 'bold' : 'normal' }}>{presenter.company}</li>
                     )
                  }
               </ol>
            </section>
         }
         <h6>Full agenda: <a target='_blank' rel='noreferrer' href="https://youngstartup.com/upcoming/agenda">Click here</a></h6>
      </div>
   )
}

