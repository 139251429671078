import { Link, useNavigate } from 'react-router-dom';
import { svgs } from '../assets/svgs';
import { useState } from 'react';
import { postReq } from '../utils/apiCalls';


export const AccountCreated = () => {
    document.title = "youngStartup Account | AccountCreated";
    const [countdown, setCountdown] = useState(null);
    const [msg, setMsg] = useState({ message: '', success: false })
    const navigate = useNavigate()
    const email = localStorage.getItem('email')

    const data = {
        body: {
            email: localStorage.email
        }
    }

    const resendEmail = (e) => {
        e.preventDefault()
        if (countdown) return
        setCountdown(180)
        setMsg({ message: '', success: false })
        const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownInterval);
                    return null;
                }
                return prevCountdown - 1;
            })
        }, 1000);
        if (!data.body.email) {
            return navigate('/login')
        }
        postReq('https://youngstartup.io/api/website/resendconfirmemail', data)
            .then((res) => {
                if (!res.ok) throw new Error('An error occurred')
                return res.json()
            })
            .then((res) => { setMsg({ message: res.message, success: res.success }) })
            .catch((err) => { setMsg({ message: err.message, success: false }) })
    }

    return (
        <div className='account-created'>
            <h3>CHECK YOUR EMAIL FOR A CONFIRM LINK</h3>
            <p>We’ve sent a confirm account link to <span>{email}</span>. The link expires shortly, so please enter it soon.</p>
            <p>Didn’t receive it? Check your junk/spam folder or <a href="" onClick={resendEmail}>resend the email</a>.</p>
            {msg.message && <div className={msg.success ? 'msg green' : 'msg'}>{msg.message}</div>}
            {countdown && <h4>Please wait to resend the confirmation email.</h4>}
            {countdown && <section className='timer-container'>
                <div className='loader' />
                <div className='timer'>{countdown}</div>
            </section>}
            <section className='email-links'>
                <a href="https://mail.google.com/mail/u/0/" target="_blank" >{svgs.google} Open Gmail</a>
                <a href="https://outlook.live.com/mail/0/inbox" target="_blank" >{svgs.outlook} Open Outlook</a>
            </section>
            <h4>Back to <Link to='/signup'>Create Account</Link></h4>
        </div>
    )
}





