import { EventDialog, PriceDialog, SOFormDialog, SubmittedDialog } from '../components';
import { createContext, useEffect, useState } from 'react';
import { getData, getReq } from '../utils/apiCalls';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParameterByName } from '../utils/funcs';

export const SOFormContext = createContext(null);

export const SOForm = ({ formId }) => {
    document.title = "youngStartup Account | SOForm"
    const navigate = useNavigate()
    const location = useLocation()
    const [SOFormUser, setSOFormUser] = useState({
        currStage: 'Loading',
        msg: '',
        formData: {},
    })

    const tok = getParameterByName('tok')
    if (tok) {
        localStorage.setItem('tok', tok)
    }


    useEffect(() => {
        getReq("https://youngstartup.io/api/db/website/so_forms/all")
            .then((res) => {
                if ([405, 500].includes(res.status)) {
                    throw new Error('An error occurred')
                }
                if ((res.status === 404)) {
                    throw new Error('The application could not be found. Please contact us at topinnovators@youngstartup.com.')
                }
                if ((res.status === 401)) {
                    logout()
                }
                if (!res.ok) {
                    return res.json().then((data) => {
                        throw new Error(data.message)
                    })
                }
                return res.json()
            })
            .then(data => {
                if (formId === 'fullsoform') {
                    setSOFormUser({ ...SOFormUser, currStage: "SOFormDialog", formData: data });
                } else if (data.presenting_virtually) {
                    setSOFormUser({ ...SOFormUser, currStage: "PriceDialog", formData: data });
                } else {
                    setSOFormUser({ ...SOFormUser, currStage: 'EventDialog', formData: data });
                }
            })
            .catch((err) => { setSOFormUser(err.message) })
    }, []);

    const logout = () => {
        localStorage.removeItem('tok')
        navigate("/login?red_to=" + location.pathname)
    }

    return (
        <div className='soform'>
            <header>
                <img
                    alt="YSV-Logo"
                    src="/assets/media/ysv-logo-black-new.png"
                />
                <h5>Questions or issues? Please contact <a href="mailto:topinnovators@youngstartup.com">topinnovators@youngstartup.com</a></h5>
                <div className='header-btns'>
                    <button className='btn' onClick={logout}>Logout</button>
                </div>
            </header>
            <main>
                <SOFormContext.Provider value={{ SOFormUser, setSOFormUser }}>
                    {SOFormUser.currStage === 'Loading' && <div id="loader" />}
                    {SOFormUser.currStage === 'EventDialog' && <EventDialog />}
                    {SOFormUser.currStage === 'PriceDialog' && <PriceDialog />}
                    {SOFormUser.currStage === 'SOFormDialog' && <SOFormDialog formId={formId} />}
                    {SOFormUser.currStage === 'SubmittedDialog' && <SubmittedDialog />}
                </SOFormContext.Provider>
            </main>
        </div>
    )
}





