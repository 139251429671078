import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LandingContext } from '../../pages/LandingContext';
import { getData, patchData } from '../../utils/apiCalls';
import { getMonthAndDayWithSuffix } from '../../utils/funcs';
import { SubmitButton } from '../SubmitButton';

import styles from './Guide.module.css';
export const Guide = () => {
   const [eventData, setEventData] = useState({})
   const { landingData, updateLandingData } = useContext(LandingContext)
   const { presenting_round, presenting_order_slot } = landingData.attendee_data
   const { final_presenting_track, ticket_type } = landingData.user.so_application.so_marking
   const { so_inclusion } = landingData
   const { datetime_start } = landingData.presenting_session || {}
   const [companyName, setCompanyName] = useState(landingData.user.so_application.company_name)

   useEffect(() => {
      getData('https://youngstartup.io/api/cwebsite/get_curr_event')
         .then((res) => setEventData(res))
   }, [])

   const handelSubmit = (e) => {
      e.preventDefault()
      patchData(`https://youngstartup.io/api/db/website/so_forms/update`, {
         body: {
            company_name: companyName
         }
      }).then(res =>
         updateLandingData()
      )
   }

   return (
      <div className={styles.guide}>
         <section className={styles.venue}>
            <h5>Venue:</h5>
            <h6>{eventData.venue_name}</h6>
            <p>{eventData.full_address}</p>
            <br />
            <h6>Directions & Parking</h6>
            <div className={styles.directionsIcons}>
               <button>
                  <a href={eventData.location_links?.waze} target='_blank' rel='noreferrer'></a>
               </button>
               <button>
                  <a href={eventData.location_links?.google} target='_blank' rel='noreferrer'></a>
               </button>
            </div>
            <br />
            <h6>Please pick up your badge at the registration table upon arriving and wear it at ALL times.</h6>
            <br />
            <h6>Exclusive Hotel Discounts:</h6>
            <p>
               <a href='https://youngstartup.com/upcoming/venue#hotels' target='_blank' rel='noreferrer'>Click here.</a>
            </p>
         </section>

         {
            (final_presenting_track?.toLowerCase() !== 'seed' || so_inclusion.toLowerCase() === 'yes') &&
            <section className={styles.outline}>
               <h5>Summary Outline:</h5>
               <p>
                  If you’d like to make any changes to your company’s <NavLink to="/landing/summary">Summary Outline</NavLink>, which will be printed in the event guide, please do so by no later than &nbsp;
                  <strong>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</strong>
               </p>
               {/* <<SO Deadline>> */}
               <br />
               <h6>Please note:<p>Any Updates made after that date may <strong>NOT</strong> be included in the event guide as we will submit the final event guide to the printers.</p></h6>
            </section>
         }
         <section className={styles.companyName}>
            <h5>Company Name:</h5>
            <form onSubmit={handelSubmit}>
               <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
               <button>
                  <SubmitButton color='#0095C3' bgColor='#0F1A28' borderColor='white' clickable={true} />
               </button>
            </form>
            <p>This is how your company name will be printed on your badges and in the event guide.</p>
            <br />
            <h6>Please note:<p>NO taglines and/or business structure abbreviations (ie: Inc, LLC, etc) will be added to the names.</p></h6>
         </section>

         {(final_presenting_track === 'Seed'
            && (
               !ticket_type.toLowerCase().includes("gold")
               && !ticket_type.toLowerCase().includes("platinum")
            )
         ) ?
            <section className={styles.parteringTool}>
               <h5>Partnering Tool:</h5>
               <p>Silver level ticket does Not include partnering access. If you’d like to upgrade, contact Rivka <a href='mailto:rivka@youngstartup.com'>rivka@youngstartup.com</a>.</p>
            </section>
            : <section className={styles.parteringTool}>
               <h5>Partnering Tool:</h5>
               <p>A few days before the Summit, we will launch our partnering tool which will allow you to schedule meetings with investors and other participants.</p>
               <br />
               <p>You will receive an email with instructions on how to access the system once the tool is ready to be launched.</p>
               <br />
               <p>As an added benefit, note that as a presenter you will have earlier access than the regular Summit attendees.</p>
            </section>}

         <section className={styles.presentationSchedule}>
            <h5>Presentation Schedule:</h5>
            <p>A schedule with your specific presentation time at the Venture Summit will be forthcoming from Rivka via email a week before the Summit and will be available below.</p>
            <br />
            <h6>Date & Time:<p>{datetime_start ? getMonthAndDayWithSuffix(datetime_start) : "check back soon"}</p></h6>
            <h6>Round:<p>{presenting_round || "check back soon"}</p></h6>
            <h6>Placement:<p>{presenting_order_slot || "check back soon"}</p></h6>
            <h6>Full agenda:<a target='_blank' rel='noreferrer' href="https://youngstartup.com/upcoming/agenda">Click here</a></h6>
         </section>
         <section className={styles.presentationRoom}>
            <h5>Your Presentation:</h5>
            <ul>
               <li>Your presentation will be {final_presenting_track?.toLowerCase() !== 'seed' ? 6 : 3} minutes long.</li>
               <li>A moderator will be assigned to each room and will load all slides for the presenters.</li>
               <li>Microphone, clicker and laser pointer will be provided.</li>
               <li>Each room will be equipped with a timer in full view of the presenter. Presentation moderators will provide 2 and 1 minute warnings.  The time limits will be strictly enforced by the room moderator.</li>
               <li>Presenters will NOT be permitted to connect a laptop to the digital projector in the presentation room.</li>
               <li>Presenters will NOT be permitted to connect a usb stick to the laptop on the podium. Your presentation MUST be uploaded to your account.</li>
               <li>Presentation rooms will be equipped with audio.</li>
            </ul>
         </section>
         {/* <section className={styles.coaching}></section> */}
      </div >
   )
}
