import { useContext, useEffect, useState } from 'react';
import { svgs } from '../../assets/svgs';
import { LandingContext } from '../../pages/LandingContext';
import styles from './DynamicInputs.module.css';
import { updateObjectById } from '../../utils/funcs';
import { v4 as uuidv4 } from "uuid";


export const DynamicInputs = ({ input, setDynamicValues }) => {
   const { landingData } = useContext(LandingContext)
   const [values, setValues] = useState([])

   useEffect(()=>{
      setDynamicValues(prev => ({ ...prev, [input.internal_name]: values }))
   },[values])

   useEffect(() => {
      setValues(JSON.parse(landingData.user.so_application[input.internal_name]))
   }, []) // eslint-disable-line

   const handleChange = (id, e) => {
      let newValues = updateObjectById(values, id, { "name": e.target.value })
      setValues(newValues);
   }

   const addFormFields = () => {
      if (values.length > input.validations.limit) return
      setValues([...values, { name: "", id: uuidv4() }])
   }

   const removeFormFields = (id) => {
      if (values.length === 1) return
      setValues(values.filter(i => i.id !== id))
   }

   if (typeof values === 'string' || values instanceof String) return <></>
   if (!values || values.length === 0) return <></>
   return (
      <div className={styles.dynamicInputs}>
         {
            values.map((element, index) => (
               <div key={element.id} className={styles.input}>
                  <input placeholder={input.child_label} id='name' type="text" name="name" value={element.name || ""} onChange={e => handleChange(element.id, e)} />
                  <button className={styles.removeBtn} type="button" onClick={() => removeFormFields(element.id)}>{svgs.minus}</button>
                  <button className={styles.addBtn} type="button" onClick={() => addFormFields()}>{svgs.plus}</button>
               </div>
            ))
         }
      </div>
   )
}