import { useContext, useEffect, useState } from 'react';
import { svgs } from '../../assets/svgs';
import { SOFormContext } from "../../pages/SOForm"
import { updateObjectById } from '../../utils/funcs';
import { v4 as uuidv4 } from "uuid";


export const DynamicInputs = ({ input, setInvalidFields }) => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [values, setValues] = useState([])


    useEffect(() => {
        setValues(JSON.parse(SOFormUser.formData[input.internal_name]))
    }, [])

    useEffect(() => {
        setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, [input.internal_name]: JSON.stringify(values) } })
    }, [values])

    const handleChange = (id, e) => {
        setInvalidFields(prev => prev.filter(i => i !== input.internal_name))
        setValues(updateObjectById(values, id, { ['name']: e.target.value }))
    }

    const addFormFields = () => {
        if (values.length > input.validations.limit) return
        setValues([...values, { name: "", id: uuidv4() }])
    }

    const removeFormFields = (id) => {
        if (values.length === 1) return
        setValues(values.filter(i => i.id !== id))
    }

    if (!values || values.length === 0) {
        setValues([{ name: "", id: uuidv4() }])
    }

    if (!values) return <></>

    return (
        <div className='dynamic-inputs'>
            {
                values.map((element, index) => (
                    <div key={element.id} className='input dynamic'>
                        <input placeholder={input.child_label} id={input.internal_name + index} type="text" name={input.internal_name + index} value={element.name || ""} onChange={e => handleChange(element.id, e)} />
                        <button className='remove-btn' type="button" onClick={() => removeFormFields(element.id)}>{svgs.minus}</button>
                        <button className='add-btn' type="button" onClick={() => addFormFields()}>{svgs.plus}</button>
                    </div>
                ))
            }
        </div>
    )
}