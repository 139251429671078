import { useContext, useEffect, useState } from 'react'
import { LandingContext } from '../../pages/LandingContext'
import { postData } from '../../utils/apiCalls'
import { getMonthAndDayWithSuffix } from '../../utils/funcs'
import { SubmitButton } from '../SubmitButton'
import styles from './AssignPass.module.css'
const msgs = {
   duplicateEmail: 'Email address already submitted!',
   required: 'Fields are required',
   invalidEmail: 'Invalid email',
   valid: 'Valid fields',
}


const inputs = [
   { type: 'text', name: 'firstname', label: 'First Name' },
   { type: 'text', name: 'lastname', label: 'Last Name' },
   { type: 'email', name: 'email', label: 'Email Address' },
   { type: 'select', name: 'cocktail_reception', label: 'Attending Reception' },
]

export const AssignPass = ({ ticket, tickets }) => {
   const { landingData, updateLandingData } = useContext(LandingContext)

   const [msg, setMsg] = useState('')

   const matchmakingAccessTime = (landingData.user.so_application.so_marking.final_presenting_track === 'Seed' &&
      landingData.user.so_application.so_marking.ticket_type?.toLowerCase().includes("gold"))
      ? new Date(landingData.eventData.late_mm_date)
      : new Date(landingData.eventData.early_mm_date)

   const [values, setValues] = useState({
      id: ticket.id,
      firstname: ticket.firstname,
      lastname: ticket.lastname,
      email: ticket.email,
      cocktail_reception: ticket.cocktail_reception,
   })

   const handelSubmit = (e) => {
      e.preventDefault()
      if (msg !== msgs.valid && msg !== '') return
      postData(
         'https://youngstartup.io/api/db/website/p-passes/update',
         { body: values }
      ).then(res => {
         updateLandingData()
      })
   }

   const handelChange = (e) => {
      let newValues = values;
      newValues[e.target.name] = e.target.value;
      setValues(prev => ({ ...prev, newValues }));
      if (Object.keys(values).every((k) => values[k])) {
         if (e.target.validity.valid === false) {
            e.target.name === 'email' ? setMsg(msgs.invalidEmail) : setMsg(msgs.required)
         } else if (values.email && tickets.filter(ticket => ticket.id !== values.id).map(ticket => ticket.email).includes(values.email)) {
            setMsg(msgs.duplicateEmail)
         } else setMsg(msgs.valid)
      } else setMsg(msgs.required)

   }

   return (
      <div className={styles.assignPass}>
         <form onSubmit={handelSubmit}>
            {inputs.map((input, inputIdx) =>
               <div key={inputIdx} className={`${styles.input} ${input.type === 'select' && styles.select} `}>
                  {
                     input.type !== 'select' &&
                     <input
                        placeholder={`enter your ${input.label}`}
                        id={input.name}
                        type={input.type}
                        required
                        name={input.name}
                        value={values[input.name] || ''}
                        onChange={handelChange}
                     />
                  }
                  {
                     input.type === 'select' &&
                     <select
                        name={input.name}
                        id={input.label}
                        onChange={handelChange}
                        value={values[input.name] || ''}
                        required
                     >
                        <option value="" disabled>Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="maybe">Maybe</option>
                     </select>
                  }
                  <label htmlFor={input.id}>{input.label}</label>
                  <div className={styles.underline}></div>
                  <div className={styles.error}>{`invalid ${input.label}`}</div>
               </div>
            )
            }
            <button>
               <SubmitButton
                  color='#0095C3' bgColor='#0F1A28' borderColor='white'
                  clickable={msg === msgs.valid || (msg === '' && values.email)}
               />
               <p style={{ color: msg === msgs.valid ? 'green' : 'red' }}>{msg}</p>
            </button>

         </form>
         {
            (landingData.user.so_application.so_marking.final_presenting_track === 'Seed'
               && (
                  !landingData.user.so_application.so_marking.ticket_type?.toLowerCase().includes("gold")
                  && !landingData.user.so_application.so_marking.ticket_type?.toLowerCase().includes("platinum")
               )
            )
               ? <></>
               : <section className={styles.matchmakingCode}>
                  {matchmakingAccessTime < new Date()
                     ? <p>Matchmaking (submit ticket information first):</p>
                     : <p>Matchmaking (submit ticket information first): Check back after <strong>{getMonthAndDayWithSuffix(matchmakingAccessTime.setDate(matchmakingAccessTime.getDate()))}</strong> for access</p>
                  }
                  <div >
                     <p>Code:</p>
                     <strong>{matchmakingAccessTime < new Date() && ticket.brella_code}</strong>
                  </div>
                  <button style={{ background: matchmakingAccessTime < new Date() ? '#0F1A28' : '#5C636B' }}>
                     {
                        matchmakingAccessTime < new Date() ? <a target='_blank' rel='noreferrer' href={`https://next.brella.io/join/${ticket.brella_code}`}>Join</a> : 'Join'
                     }
                  </button>
               </section>
         }
      </div>
   )
}