import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { LandingContext } from '../../pages/LandingContext';
import { getMonthAndDayWithSuffix } from '../../utils/funcs';
import styles from './Home.module.css';

export const Home = () => {
   const { landingData } = useContext(LandingContext)
   const { so_inclusion } = landingData
   const { final_presenting_track } = landingData.user.so_application.so_marking
   return (
      <div className={styles.home}>
         <h1>Please submit the following details to maximize your company's exposure and overall experience at the Summit</h1>
         <ol>
            {
               (final_presenting_track?.toLowerCase() !== 'seed' || so_inclusion.toLowerCase() === 'yes') &&
               <li>
                  Review your Company Outline and make sure it's investor-ready by&nbsp;
                  <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span> <NavLink to="/landing/summary">Click Here</NavLink>.
               </li>
            }
            {/* <li>
               Opt-In (or out) of the Company Coaching Session (full information in Coaching tab) by&nbsp;
               <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span>
            </li> */}
            <li>
               Upload your company Logo, One-Liner, and featured person by&nbsp;
               <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span> <NavLink to="/landing/info">Click Here</NavLink>.
            </li>
            <li>
               Assign your company's attendee tickets by&nbsp;
               <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_2)}</span> <NavLink to="/landing/assign">Click Here</NavLink>.
            </li>
            <li>
               Upload your company’s presentation file by&nbsp;
               <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_3)}</span> <NavLink to="/landing/presentation_upload">Click Here</NavLink>.
            </li>
         </ol>
      </div>
   )
}
