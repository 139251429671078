export async function getData(url) {
   const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
   });
   return response.json();
}
export async function getReq(url) {
   const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
   });
   return response;
}

export async function postData(url, data) {
   const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
   });

   return response.json();
}

export async function postReq(url, data) {
   const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
   });

   return response;
}

export async function patchReq(url, data) {
   const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
         "Content-Type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
   });

   return response;
}

export async function patchData(url, data) {
   const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
         "Content-type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      body: JSON.stringify(data),
   });
   return response.json();
}

export async function putData(url, data) {
   const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
         "Content-type": "application/json",
         tok: localStorage.tok,
         'Accept': 'application/json'
      },
      body: JSON.stringify(data),
   });
   return response.json();
}


