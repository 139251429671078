import { useContext, useEffect, useRef, useState } from "react"
import { SOFormContext } from "../../pages/SOForm"
import { getReq, postReq } from "../../utils/apiCalls"
import { useLocation, useNavigate } from "react-router-dom"

export const PriceDialog = () => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [fundingRaised, setFundingRaised] = useState("")
    const [fundingSeeking, setFundingSeeking] = useState("")
    const [pricingStage, setPricingStage] = useState("")
    const [msg, setMsg] = useState('')
    const location = useLocation()
    const navigate = useNavigate()
    const pricingStageRef = useRef(null)

    const seedStage = [
        'Guaranteed Presentation Slot',
        '1 attendee ticket with unlimited access',
        'Access to leading VCs, Corporate VCs, private investors, and investment bankers',
        '3-minute presentation',
        'One-on-One Matchmaking & Networking app',
        'Two-page Company Profile published in digital event guide distributed to all attendees and investors for an additional $195',
        'Media Exposure',
    ]

    const ventureStage = [
        'Guaranteed Presentation Slot',
        '3 attendee tickets for company executives',
        'Access to leading VCs, Corporate VCs, private investors, and investment bankers',
        '6-minute presentation slot',
        'One-on-One Matchmaking & Networking app (early access)',
        'Two-page Company Profile published in digital event guide distributed to all attendees and investors',
        'Additional discounted tickets available for your team',
        'Media Exposure',
        // 'Coaching Session with Investors(online, 1 - 2 weeks before the summit)',
        '3 tickets to Cocktail Reception',
    ]

    useEffect(() => {
        getReq('https://youngstartup.io/api/cwebsite/get_current_event')
            .then(res => res.json())
            .then(res => setSOFormUser({ ...SOFormUser, eventData: res }))
    }, [])

    useEffect(() => {
        setMsg('')
        const stage = determinePricingStage()
        setPricingStage(stage)
        if (stage) {
            pricingStageRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [fundingRaised, fundingSeeking])

    const determinePricingStage = () => {
        if (fundingRaised === "moreThan3M" && fundingSeeking) {
            return "growth";
        }
        if (fundingRaised === "between500kAnd3M" && fundingSeeking) {
            return "venture";
        }
        if (fundingRaised === "lessThan500k") {
            if (fundingSeeking === "lessThan2M") return "seed";
            if (fundingSeeking === "moreThan2M") return "venture";
        }
        return "";
    }

    const handelSubmit = () => {
        if (!pricingStage) return setMsg('Please answer the two above questions to select your Company Stage')
        getReq(`https://youngstartup.io/api/website/user/analytics/accepted_pricing?pricing_stage=${pricingStage}`)
            .then((res) => {
                if ((res.status === 401)) {
                    localStorage.removeItem('tok')
                    navigate("/login?red_to=" + location.pathname)
                }
            })
            .then(() => setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, pricing_stage: pricingStage }, currStage: 'SOFormDialog' }))
    }

    const handelDecline = () => {
        getReq('https://youngstartup.io/api/website/user/analytics/declined_pricing')
            .then(() => localStorage.removeItem('tok'), navigate('/login'))
    }

    return (
        <div className='price-dialog dialog'>
            <h1>Top Innovator Application</h1>
            <h2>Get Noticed. Get Funded. Grow Faster.</h2>
            <h5>There is no cost to apply. </h5>
            {/* <p><span>The Venture Summit</span> will honor <span>more than 100 Top Innovators</span> by offering them the opportunity to present their company's vision to groups of Active Venture Capitalists, Corporate VCs, Private Investors, Investment bankers, Press, fellow entrepreneurs, and corporate development officers.</p> */}
            <h5>Please answer the following questions to determine your Company Stage: </h5>
            <h6>If selected to present, the indicated fees would be needed in order to confirm your presentation slot.</h6>
            <section>
                <label htmlFor="fundingRaised">What is the total amount of funding your company has raised to date?</label>
                <select
                    id="fundingRaised"
                    name="fundingRaised"
                    value={fundingRaised}
                    onChange={(e) => { setFundingRaised(e.target.value) }}
                >
                    <option value="" disabled>Select an option</option>
                    <option value="lessThan500k">Less than $500K</option>
                    <option value="between500kAnd3M">Between $500k-$3M</option>
                    <option value="moreThan3M">More than $3M</option>
                </select>
                <label htmlFor="fundingSeeking">What is the total amount of funding your company is currently seeking?</label>
                <select
                    id="fundingSeeking"
                    name="fundingSeeking"
                    value={fundingSeeking}
                    onChange={(e) => { setFundingSeeking(e.target.value) }}
                >
                    <option value="" disabled>Select an option</option>
                    <option value="lessThan2M">Less than $2M</option>
                    <option value="moreThan2M">More than $2M</option>
                </select>
            </section>
            <section ref={pricingStageRef}>
                <div className={`${pricingStage === 'growth' ? 'selected' : ''}`}>
                    <span>Growth Stage:</span> $1985 <br /> For companies that have raised more than $3M
                </div>
                <div className={`${pricingStage === 'venture' ? 'selected' : ''}`}>
                    <span>Venture Stage:</span> $1785 <br /> For companies that have raised less than $3M
                </div>
                <div className={`${pricingStage === 'seed' ? 'selected' : ''}`}>
                    <span>Seed Stage:</span> No cost to present beyond price of Summit Registration (Ticket Purchase Required $497)<br />
                    For companies that BOTH:
                    <ol>
                        <li>Have raised less than $500k in outside funding AND</li>
                        <li>Are raising a Seed/Angel round of $2M or less</li>
                    </ol>
                </div>
            </section>
            <h5>If selected, benefits would include:</h5>
            {!pricingStage && <p>Please select a stage to see the benefits thereof.</p>}
            <ul>
                {pricingStage && (pricingStage === 'seed' ? seedStage : ventureStage).map((benefit, idx) =>
                    <li key={idx}>{benefit}</li>
                )}
            </ul>
            <h4>{SOFormUser.eventData?.pricing_message}</h4>
            {msg && <h4 className='message'>{msg}</h4>}
            <div className='btns'>
                <button onClick={handelDecline} className='btn'>Decline</button>
                <button onClick={handelSubmit} className='btn'>Continue</button>
            </div>
        </div>
    )
}





