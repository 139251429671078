import { useContext, useEffect, useRef, useState } from 'react';
import { SOFormContext } from '../../pages/SOForm';
import { convertBase64 } from '../../utils/funcs';
import { svgs } from '../../assets/svgs';

export const FileInput = ({ input }) => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [value, setValue] = useState()
    const inputRef = useRef(null)

    useEffect(() => {
        setValue(SOFormUser.formData[input.internal_name])
    }, [])
    
    useEffect(() => {
        setSOFormUser({ ...SOFormUser, formData: { ...SOFormUser.formData, [input.internal_name]: value } })
    }, [value])
    
    const handelChange = (e) => {
        convertBase64(e.target.files[0]).then((res) => setValue(res))
    }

    return (
        <div className='file-input'>
            {value && <img src={value} alt="company logo" />}
            <input hidden accept={input.validations?.accept || '*'} ref={inputRef} onChange={handelChange} id={input.internal_name} type="file" name={input.internal_name} value={''} />
            <button type='button' onClick={() => inputRef.current.click()}>Upload Image {svgs.uploadImg}</button>
        </div>
    )
}